/**
 * @module Split Section - Image + Content
 * @description Split Section 50/50
 */
import { useEffect, useState } from 'react';
import Image from 'next/image';
import { fastly } from '@/js/utils/helpers';
import state from '@/js/config/state';
import DynamicButton from '@/blocks/Buttons/DynamicButton';
import styles from './index.module.css';

const defaultItems = [
  'Amazon eero Wi-Fi 6E System',
  <>FREE voice line (plus taxes and surcharges)<span key="span-1" data-legal="free-voice-line" /></>,
  <>Free expert installation</>
];

const defaultImage = {
  url: '/pages/home/split-section-720x514.jpg',
  blurUrl: '/pages/home/split-section-720x514.jpg',
  alt: 'Woman wearing VR - 2G text',
  className: ''
};

const position = 'SPLIT SECTION';

/**
 * @function Component
 * @description
 */
const Component = ( { ...props } ) => {
  const {
    title,
    subtitle,
    content,
    showItems,
    items = defaultItems,
    legal,
    dualCTA,
    theme,
    reverseLayout,
    reverseMobile,
    componentList,
    customStyles,
    objectFit = 'cover'
  } = props;

  let { image = defaultImage } = props;
  image = { ...image, defaultImage };

  const [ hideDesktopBackground, setHideDesktopBackground ] = useState( true );

  const determineViewport = () => {
    if ( ! state.is_mobile ) {
      return setHideDesktopBackground( false );
    }

    return setHideDesktopBackground( true );
  };

  useEffect( () => {
    determineViewport();
    document.addEventListener( 'redventures/resize_executed', determineViewport );
  }, [] );

  return (
    <section className={ `component ${ styles.component } ${ reverseMobile && styles.componentMobileReverse } ${ theme && `u-theme-${ theme }` }` }>
      <div className={ `${ styles.component__backgroundWrap } ${ styles.component__backgroundWrapMobile } ${ ( objectFit === 'contain' ) && styles.component__backgroundWrapContain }` }>
        <div className={ `
          ${ styles.component__background }
          ${ styles.component__backgroundMobile } ` }>
          <Image
            alt={ image.alt }
            src={ image.url }
            loader={ fastly }
            className={ `component__background_img ${ image.className }` }
            loading="lazy"
            width={ 320 }
            height={ 230 }
          />
        </div>{ /* <!-- .component__background --> */}
      </div>

      <div className={ `l-container ${ customStyles && customStyles.component__layoutContainer }` }>
        <div className={ `g-row g-row--match-height ${ ! reverseLayout && 'g-row--reorder-2-col' }` }>
          <div className="g-col g-col--one-half">
            <div className={ `${ styles.component__content } ${ reverseLayout && styles.component__contentReverse }` }>
              <h2 className={ `${ styles.component__title } ${ customStyles && customStyles.component__title }` }>{ title }</h2>
              { subtitle && <p className={ `h6 ${ styles.component__subtitle }` }>{ subtitle }</p> }

              { content && <p>{ content }</p>}

              { showItems !== false ? (
                <ul className={ styles.component__list }>
                  { componentList ?
                    ( componentList.map( ( { headline, subhead }, index ) => (
                          <li key={ index }>
                            <h3 className="h5">{ headline }</h3>
                            <p>{ subhead }</p>
                          </li>
                    ) ) ) :
                    ( items ? items.map( ( item, index ) => (
                        <li key={ index }>
                          <span className={ `icon icon-checkmark1 ${ styles.component__icon } ${ theme && styles[ `component__icon__${ theme }` ] }` } />
                          { item }
                        </li>
                    ) ) :
                      ''
                    )
                  }
                </ul>
              ) : ''
              }
              <p className={ `legal ${ styles.component__legal }` }>{ legal }</p>

              <DynamicButton
                className={ `btn ${ dualCTA && styles.component__buttonStyle }` }
                type="cart"
                position={ position }
              />

              { dualCTA &&
                <DynamicButton
                  className={ `btn btn--blackOutline ${ styles.component__buttonStyle }` }
                  href={ dualCTA }
                  position={ position }>
                  Learn More
                </DynamicButton>
              }
            </div>{ /* <!-- .component__content --> */}
          </div>{ /* <!-- .g-col --> */}

          <div className="g-col g-col--one-half">
          { ! hideDesktopBackground &&
              <div className={ `${ styles.component__backgroundWrap } ${ reverseLayout && styles.component__backgroundWrapReverse }` }>
                <div className={ `${ styles.component__background }` }>
                  <Image
                    alt={ image.alt }
                    src={ image.url }
                    className={ `component__background_img ${ image.className }` }
                    loader={ fastly }
                    loading="lazy"
                    width={ 720 }
                    height={ 582 }
                  />
                </div>
              </div>
          }
          </div>{ /* <!-- .g-col --> */ }
        </div>{ /* <!-- .g-row --> */ }
      </div>
    </section>
  );
};

Component.defaultProps = {
  title: <>Headline</>,
  subtitle: null,
  componentList: null,
  items: defaultItems,
  legal: '',
  image: defaultImage,
  theme: null,
  reverseLayout: false
};

export default Component;
